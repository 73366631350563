import React, { ReactNode, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Image from "next/image";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GTranslateIcon from "@material-ui/icons/GTranslate";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import Typography from "@material-ui/core/Typography";

import RateReviewOutlinedIcon from "@material-ui/icons/RateReviewOutlined";
import BookmarkBorderOutlinedIcon from "@material-ui/icons/BookmarkBorderOutlined";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";
import PlaylistAddOutlinedIcon from "@material-ui/icons/PlaylistAddOutlined";

import firebase from "@Config/firebaseInit";
import AccountMenu from "@Components/appBar/accountMenu";
import Logo from "@Components/common/logo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    margin: {
      margin: theme.spacing(1),
      width: 240,
      // color: "#fff",
    },
    dialogTitle: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    titleContainer: {
      display: "flex",
      // justifyContent: "center",
      alignItems: "center",
    },
    dialogContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: { flexDirection: "column" },
    },
    providers: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // margin: 0,
      minWidth: 300,
      [theme.breakpoints.down("sm")]: { marginBottom: theme.spacing(4) },
    },
    features: {
      minWidth: 300,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
    },
  })
);

const providerList = [
  { name: "Google", color: "#B83540", icon: <GTranslateIcon /> },
  { name: "Twitter", color: "#62ADC4", icon: <TwitterIcon /> },
  { name: "Facebook", color: "#4F84C4", icon: <FacebookIcon /> },
];

const list = [
  { name: "投稿", desc: "コメントの投稿", icon: <RateReviewOutlinedIcon /> },
  {
    name: "編集",
    desc: "画像の追加や編集の提案",
    icon: <AddPhotoAlternateOutlinedIcon />,
  },
  {
    name: "ブックマーク",
    desc: "記事や宿の保存",
    icon: <BookmarkBorderOutlinedIcon />,
  },
  {
    name: "リスト",
    desc: "「泊まった宿」等のリストの作成",
    icon: <PlaylistAddOutlinedIcon />,
  },
];

export default function AccountButton() {
  const classes = useStyles();
  const theme = useTheme();
  const [user, loading, error] = useAuthState(firebase.auth());

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleAccountMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAccountMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      {user ? (
        <IconButton
          aria-label="account of current user"
          color="inherit"
          aria-haspopup="true"
          onClick={handleAccountMenu}
        >
          {user.photoURL ? (
            <Avatar
              alt={user.displayName || ""}
              src={user.photoURL}
              className={classes.small}
            />
          ) : (
            <Avatar alt={user.displayName || ""} className={classes.small} />
          )}
        </IconButton>
      ) : (
        <Button
          size="small"
          color="inherit"
          onClick={handleClickOpen}
          // href="/login"
        >
          ログイン
        </Button>
      )}

      <Dialog
        fullScreen={fullScreen}
        // fullWidth={true}
        maxWidth="md"
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="responsive-dialog-title"
        >
          <div className={classes.titleContainer}>
            <Logo disableLink />
            ：ログイン
            <div style={{ flexGrow: 1 }}></div>
            <IconButton onClick={handleClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.providers}>
            <Typography variant="h6">以下のサービスでログイン：</Typography>
            {providerList.map((item, i) => {
              return (
                <Button
                  key={i}
                  variant={
                    theme.palette.type === "dark" ? "contained" : "outlined"
                  }
                  size="large"
                  style={
                    theme.palette.type === "dark"
                      ? {
                          backgroundColor: item.color,
                          color: theme.palette.text.primary,
                        }
                      : { borderColor: item.color, color: item.color }
                  }
                  className={classes.margin}
                  startIcon={item.icon}
                >
                  {item.name}
                </Button>
              );
            })}
          </div>

          <Divider />
          <div className={classes.features}>
            <List dense>
              {list.map((item, i) => {
                return (
                  <ListItem key={i}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.name} secondary={item.desc} />
                  </ListItem>
                );
              })}
            </List>
            など、様々な機能が利用できます。
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      <AccountMenu anchorEl={anchorEl} onClose={handleAccountMenuClose} />
    </React.Fragment>
  );
}

/*<React.Fragment>
  <IconButton aria-label="login" color="inherit" href="/login">
    <AccountCircleOutlinedIcon />
  </IconButton>
  ログイン
</React.Fragment>*/
