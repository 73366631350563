import React, { ReactNode } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { getImageUrl } from "@Config/graphCMSInit";

interface SEOProps {
  pageTitle?: string;
  pageDescription?: string;
  pageImg?: string;
  pageImgWidth?: number;
  pageImgHeight?: number;
  children?: ReactNode;
}

const Seo = (props: SEOProps) => {
  const { asPath, locale } = useRouter();

  const title = props.pageTitle
    ? `${locale === "ja" ? "ときやど" : "Tokiyado"} | ${props.pageTitle}`
    : "ときやど";
  const description =
    props.pageDescription ||
    "歴史的建造物に泊まって、タイムスリップしませんか。 「ときやど」は、価値ある建物を抱える宿を応援するために、現役大学生が開設しました。";
  const url = asPath;
  // const imgUrl = props.pageImg || "https://tokiyado.net/images/og-image.png";
  const imgWidth = props.pageImgWidth || 800;
  const imgHeight = props.pageImgHeight || 418;
  const imgUrl =
    props.pageImg ||
    getImageUrl("sCdwAcudRrCwiY9lHKdq", {
      width: imgWidth,
      height: imgHeight,
      file: "png",
    });

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content="レトロ旅館, 大正ロマン, 昭和レトロ, ボロ宿, 懐かし, 映え, ばえ"
      />
      <meta name="author" content="ときやどプロジェクト" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="時を感じる宿に泊まろう" />

      <meta property="og:url" content="https://tokiyado.net/" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imgUrl} />
      {/*<meta property="og:image:width" content={String(imgWidth)} />
      <meta property="og:image:height" content={String(imgHeight)} />*/}

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="tokiyado.net" />
      <meta property="twitter:url" content="https://tokiyado.net/" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imgUrl} />
      <link rel="canonical" href={url} />

      {props.children}
    </Head>
  );
};

export default Seo;
