import React, { ReactNode } from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Image from "next/image";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import TranslateOutlinedIcon from "@material-ui/icons/TranslateOutlined";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";
import AnnouncementOutlinedIcon from "@material-ui/icons/AnnouncementOutlined";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import PlayCircleOutlineOutlinedIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import AddAPhotoOutlinedIcon from "@material-ui/icons/AddAPhotoOutlined";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';

import LangMenu from "./langMenu";
import Logo from "@Components/common/logo";

const drawerWidth = 260;

const book_url =
  "https://book.gakugei-pub.co.jp/gakugei-book/9784761528850/";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      minHeight: 48,
      display: "block",
      margin: "auto",
      padding: 12,
    },
    emoji: {
      borderRadius: "unset",
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    drawerBox: {
      height: "inherit",
    },
    copyright: {
      margin: theme.spacing(2),
      alignSelf: "center",
    },
  })
);

function ListItemLink(props: ListItemProps<"a", { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

export default function PersistentDrawerLeft(props: DrawerProps) {
  const { t: t_common } = useTranslation("common");
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose } = props;
  const handleClose = () => {
    onClose();
  };

  const drawerItems: {
    name: string;
    icon: ReactNode;
    link?: string;
    disabled?: boolean;
    newtab?: boolean;
  }[][] = [
    [],
    [
      {
        name: t_common("app_bar.drawer.map"),
        icon: <MapOutlinedIcon />,
        link: "/database",
      },
      {
        name: "宿スケッチ",
        icon: <MenuBookOutlinedIcon />,
        link:book_url,
        newtab: true,
      },
      {
        name: t_common("app_bar.drawer.recent_posts"),
        icon: <AnnouncementOutlinedIcon />,
        link: "/posts",
      },
      {
        name: t_common("app_bar.drawer.tags"),
        icon: <LabelOutlinedIcon />,
        link: "/tags",
      },
      {
        name: "宿リスト",
        icon: <ListOutlinedIcon />,
        // link: "/yado",
        disabled: true,
      },
      {
        name: t_common("app_bar.drawer.wiki"),
        icon: <LibraryBooksOutlinedIcon />,
        link: "/wiki/a",
      },
    ],
    [],
    [
      {
        name: "宿情報を提供",
        icon: <NoteAddOutlinedIcon />,
        link: "https://forms.gle/2LvboUv8NEPAo6Vr9",
        newtab: true,
      },
      {
        name: "写真を提供",
        icon: <AddAPhotoOutlinedIcon />,
        link: "https://forms.gle/RS4KsPFirBGELP59A",
        newtab: true,
      },
    ],
    [],
    [
      {
        name: t_common("app_bar.drawer.about"),
        icon: <InfoOutlinedIcon />,
        link: "/about",
      },

      /* {
        name: "ソーシャルメディア",
        icon: <ShareOutlinedIcon />,
        link: "/social",
        disabled: true,
      },
      {
        name: "寄付・支援",
        icon: <FavoriteBorderOutlinedIcon />,
        link: "/donate",
        disabled: true,
      }, */
      {
        name: t_common("app_bar.drawer.contact"),
        icon: <MailOutlineOutlinedIcon />,
        link: "/contact",
      },
    ],
    [],
    /* [
      {
        name: "ロードマップ",
        icon: <FlagOutlinedIcon />,
        link: "/roadmap",
        disabled: true,
      },
      {
        name: "プライバシー",
        icon: <VerifiedUserOutlinedIcon />,
        link: "/privacy",
        disabled: true,
      },
    ],
    [],*/
  ];

  const [langAnchorEl, setLangAnchorEl] = React.useState(null);
  const handleLangMenu = (event: any) => {
    setLangAnchorEl(event.currentTarget);
  };
  const handleLangClose = () => {
    setLangAnchorEl(null);
  };

  return (
    <Drawer
      className={classes.drawer}
      anchor="left"
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <Logo long width={200} />
      </div>

      <nav>
        {drawerItems.map((arr, i) => {
          return arr.length === 0 ? (
            <Divider key={i} />
          ) : (
            <List key={i}>
              {arr.map((item, j) =>
                item.disabled ? (
                  <ListItem key={j} disabled>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItem>
                ) : (
                  <ListItemLink
                    key={j}
                    href={item.link}
                    target={item.newtab ? "_blank" : undefined}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItemLink>
                )
              )}
            </List>
          );
        })}
      </nav>

      <div className={classes.drawerBox}></div>

      <Button
        startIcon={<TranslateOutlinedIcon />}
        aria-label="language"
        color="inherit"
        aria-haspopup="true"
        onClick={handleLangMenu}
      >
        {router.locale === "ja" ? "日本語" : "English"}
      </Button>

      <LangMenu anchorEl={langAnchorEl} onClose={handleLangClose} />

      <Link href="/about" passHref>
        <a className={classes.copyright}>© 2021 Tokiyado Project</a>
      </Link>
    </Drawer>
  );
}
