import React, { ReactNode } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import BookmarkBorderOutlinedIcon from "@material-ui/icons/BookmarkBorderOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";

import firebase from "@Config/firebaseInit";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      background: "none",
    },
    toolbar: {
      padding: 0,
    },
    grow: {
      flexGrow: 1,
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    content: {},
  })
);

interface AccountMenuProps {
  anchorEl: any;
  onClose: () => void;
}

export default function PersistentDrawerLeft(props: AccountMenuProps) {
  const { t: t_common } = useTranslation("common");
  const classes = useStyles();
  const [user, loading, error] = useAuthState(firebase.auth());

  const handleClose = () => {
    onClose();
  };

  const logout = () => {
    handleClose();
    firebase.auth().signOut();
  };

  const { anchorEl, onClose } = props;

  const accountMenuItems: {
    name: string;
    icon: ReactNode;
    onClick?: () => void;
    link?: string;
  }[][] = [
    [
      {
        // name: t_common("app_bar.account_menu.my_account"),
        name:
          user?.displayName ||
          t_common("app_bar.account_menu.my_account") ||
          "",
        icon:
          user && user.photoURL ? (
            <Avatar src={user.photoURL} className={classes.small} />
          ) : (
            <Avatar className={classes.small} />
          ),
        link: "/account",
      },
      {
        name: t_common("app_bar.account_menu.bookmarks"),
        icon: <BookmarkBorderOutlinedIcon fontSize="small" />,
        link: "/account/bookmarks",
      },
    ],
    [],
    [
      /* {
        name: t_common("app_bar.account_menu.settings"),
        icon: <SettingsOutlinedIcon fontSize="small" />,
        link: "/account/settings",
      }, */
      {
        name: t_common("app_bar.account_menu.logout"),
        icon: <ExitToAppOutlinedIcon fontSize="small" />,
        onClick: () => logout(),
      },
    ],
  ];

  return (
    <Menu
      id="menu-appbar"
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      getContentAnchorEl={null}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={Boolean(props.anchorEl)}
      onClose={handleClose}
    >
      {accountMenuItems.map((arr, i) => {
        return arr.length === 0 ? (
          <Divider key={`div-${i}`} />
        ) : (
          arr.map((item, j) => (
            <MenuItem key={j} onClick={item.onClick}>
              {item.link ? (
                <Link href={item.link} passHref>
                  <a>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    {item.name}
                  </a>
                </Link>
              ) : (
                <React.Fragment>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  {item.name}
                </React.Fragment>
              )}
            </MenuItem>
          ))
        );
      })}
    </Menu>
  );
}
