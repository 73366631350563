import React, { ReactNode, useContext } from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import useDarkMode from "use-dark-mode";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Brightness4OutlinedIcon from "@material-ui/icons/Brightness4Outlined";

import { PanelsContext } from "@Pages/index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      width: "calc(100% - 400px)",
      left: 0,
      padding: "0 8px",
      background: "none",
      // backdropFilter: "blur(12px)",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    toolbar: {
      minHeight: 48,
      padding: 0,
    },
    grow: {
      flexGrow: 1,
    },
    content: {},
  })
);

export default function ThemeButton() {
  const classes = useStyles();
  const theme = useTheme();
  const { t: t_common } = useTranslation("common");
  const router = useRouter();
  const darkMode = useDarkMode();

  const [themeAnchorEl, setThemeAnchorEl] = React.useState(null);
  const handleThemeMenu = (event: any) => {
    setThemeAnchorEl(event.currentTarget);
  };

  const handleClick = (value: boolean) => {
    if (value) darkMode.enable();
    else darkMode.disable();
    router.reload();
  };

  const handleCLose = () => {
    setThemeAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label="theme"
        color="inherit"
        aria-haspopup="true"
        onClick={handleThemeMenu}
      >
        <Brightness4OutlinedIcon />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={themeAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={Boolean(themeAnchorEl)}
        onClose={handleCLose}
      >
        <MenuItem onClick={() => handleClick(false)}>
          {t_common("app_bar.theme_menu.light")}
        </MenuItem>
        <MenuItem onClick={() => handleClick(true)}>
          {t_common("app_bar.theme_menu.dark")}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
