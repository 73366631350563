import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

interface langMenuProps {
  anchorEl: any;
  onClose: () => void;
}

export default function PersistentDrawerLeft(props: langMenuProps) {
  const router = useRouter();

  const { anchorEl, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Menu
      id="menu-appbar"
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={Boolean(props.anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleClose}>
        <Link href={router.asPath} locale="ja">
          日本語
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link href={router.asPath} locale="en">
          English
        </Link>
      </MenuItem>
    </Menu>
  );
}
