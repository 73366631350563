import React, { useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

declare global {
  interface Window {
    adsbygoogle: any;
  }
}

type Variant = "square" | "horizontal" | "vertical" | "in_article" | "in_feed";

const isDev = process.env.NODE_ENV === "development";

const config: {
  [key in Variant]: {
    "data-adtest": string;
    "data-ad-layout"?: string;
    "data-ad-layout-key"?: string;
    "data-ad-format"?: string;
    "data-ad-slot": string;
    "data-full-width-responsive"?: boolean;
  };
} = {
  square: {
    "data-adtest": isDev ? "on" : "off",
    "data-ad-format": "auto",
    "data-ad-slot": "6957231028",
    "data-full-width-responsive": true,
  },
  horizontal: {
    "data-adtest": isDev ? "on" : "off",
    "data-ad-format": "auto",
    "data-ad-slot": "1550428467",
    "data-full-width-responsive": true,
  },
  vertical: {
    "data-adtest": isDev ? "on" : "off",
    "data-ad-format": "auto",
    "data-ad-slot": "5374123406",
    "data-full-width-responsive": true,
  },
  in_article: {
    "data-adtest": isDev ? "on" : "off",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-slot": "2060127565",
  },
  in_feed: {
    "data-adtest": isDev ? "on" : "off",
    "data-ad-layout-key": "-gt+q-z-fx+wv",
    "data-ad-format": "fluid",
    "data-ad-slot": "8379754216",
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { width: "100%", height: "100%", minWidth: 200, minHeight: 100 },
    header: {
      padding: theme.spacing(1),
    },
  })
);

interface DisplayAdProps {
  variant: Variant;
}



export default function DisplayAd(props: DisplayAdProps) {
  const classes = useStyles();
  const { asPath } = useRouter();
  const theme = useTheme();

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err: any) {
      console.log(err);
    }
  }, [asPath]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography className={classes.header} variant="body1" align="center">
          広告
        </Typography>
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-1875995597536577"
          {...config[props.variant]}
        ></ins>
      </div>
    </React.Fragment>
  );
}
