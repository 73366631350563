import { BooleanValueNode } from "graphql";
import Image from "next/image";
import Link from "next/link";
import useDarkMode from "use-dark-mode";

interface LogoProps {
  disableLink?: boolean;
  long?: boolean;
  width?: number;
  height?: number;
  forceTheme?: "light" | "dark";
}

export default function Logo(props: LogoProps) {
  const darkMode = useDarkMode();
  const src = `/logo/logo${props.long ? "_long" : ""}${
    props.forceTheme === "light"
      ? "_colored"
      : props.forceTheme === "dark"
      ? ""
      : darkMode.value
      ? ""
      : "_colored"
  }.svg`;

  return !props.disableLink ? (
    <Link href="/" passHref>
      <a>
        <Image
          src={src}
          alt="ときやど"
          width={props.width || 120}
          height={props.height || 40}
        />
      </a>
    </Link>
  ) : (
    <Image
      src={src}
      alt="ときやど"
      width={props.width || 120}
      height={props.height || 40}
    />
  );
}
